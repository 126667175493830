.active {
  background-color: #fff;
}

.active>svg {
  stroke: #fff;
  fill: #33AADF;
}

.active>p {
  color: #33AADF;
}